@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.heroRoot {
  @apply --backgroundImage;
  background-repeat: no-repeat;
  background-position: right center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width:767px) {
    background-position: center left 80%;
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding: 60px 24px;
  min-height: 580px;

  @media (--viewportLarge) {
    justify-content: center;
  }
}

.commonSection {
  padding: 20px 0px;

  @media (--viewportMedium) {
    padding: 40px 0px;
  }

  &.topCategorySection,
  &.popularDesignersSection {
    background-color: #f7f7f7;
  }

  & .fixWidthContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 15px;

    @media (--viewportMedium) {
      padding: 0px 24px;
    }
  }
}

.findMoreServices {
  padding: 50px 24px;
  background-image: url(../../assets/car.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  /* background-attachment: fixed; */

  @media (--viewportMedium) {
    padding: 140px 24px;
  }

  & h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 125%;
    color: #fff;
    margin-bottom: 10px;

    @media (--viewportMedium) {
      font-size: 36px;
    }
  }

  & p {
    font-size: 18px;
    line-height: 155%;
    color: #fff;
    font-weight: 400;
    margin: 0 0 30px 0;

    @media (--viewportMedium) {
      margin: 0 0 40px 0;
      font-size: 24px;
      line-height: 155%;
    }
  }


  & .seeAll {
    background-color: var(--matterColorLight);
    border: solid 1px var(--matterColorLight);
    color: var(--matterColorDark);
    font-size: 18px;
    font-weight: 500;
    line-height: 100%;
    border-radius: 0px;
    padding: 15px 30px;
    display: inline-block;
    text-decoration: none;
    transition: all ease .5s;

    @media (--viewportMedium) {
      padding: 20px 60px;
      font-size: 20px;
    }

    &:hover {
      text-decoration: none;
      transition: all ease .5s;
      background-color: transparent;
      border: solid 1px var(--matterColorLight);
      color: var(--matterColorLight);
    }
  }
}