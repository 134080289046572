.react-select__control {
  border: none !important;
  cursor: pointer !important;
  box-shadow: none !important;
}

.react-select__value-container {
  border: none !important;
  padding: 0px !important;
}

.react-select__placeholder {
  color: #c7b8b8 !important;
}

.react-select__menu {
  position: absolute !important;
}

.react-select__input {
  font-weight: 300 !important;
  font-size: 13px !important;
}