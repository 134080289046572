@import '../../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url('../../../assets/background-1440.jpg');
  background-color: var(--matterColor);
  background-size: cover;
  background-position: center center;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (--viewportLarge) {
    border-radius: 40px;
    border: solid 36px var(--matterColorBright);
  }
}

.heroContent {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 0px;
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  composes: animation;
  animation-delay: 0.5s;
  text-align: center;
  font-weight: var(--fontWeightThin);

  &>span {
    font-weight: var(--fontWeightSemiBold);
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem auto;
  width: 100%;

  @media (max-width: 575px) {
    background: none;
  }

  & .CustomSearchForm {
    height: 100%;
    width: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 575px) {
      flex-wrap: wrap;
    }
  }

  @media (--viewportLarge) {
    padding: 0;
  }
}