.searchItemsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 2;

  @media (max-width: 767px) {
    display: block;
    flex-basis: 100%;
    margin-right: 0;
    height: auto;
    border-radius: 6px;
    margin-bottom: 6px;
  }

  & .keywordSearch {
    margin-right: 0 !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    @media (--viewportLarge) {
      width: 100%;
    }

    @media (max-width: 767px) {
      margin-left: 6px !important;
    }

    @media (max-width: 575px) {
      margin-bottom: 10px;
      margin-left: 0 !important;
      padding: 10px;
      border-radius: 6px;
    }

  }
}

.dashboard {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 2;
  margin: 0 12px 0 0;
  border: 1px solid #dedede;
  border-radius: 36px;

  @media (--viewportLarge) {
    height: 50px;
  }

  @media (max-width: 767px) {
    height: 50px;
    margin-right: 8px;
  }

  @media (max-width: 575px) {
    display: block;
    flex-basis: 100%;
    margin-right: 0;
    height: auto;
    border-radius: 6px;
    margin-bottom: 6px;
  }

  &>div {
    margin-right: 0 !important;
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;

    @media (--viewportLarge) {
      width: 100%;
    }

    @media (max-width: 767px) {
      margin-left: 6px !important;
    }

    @media (max-width: 575px) {
      margin-bottom: 10px;
      margin-left: 0 !important;
      background-color: var(--matterColorLight);
      padding: 10px;
      border-radius: 6px;
      box-shadow: 0 1px 10px 0 rgb(0 0 0 / 40%);
    }
  }
}

.desktopInputRoot {
  opacity: 1;
  /* padding: 9px 0 9px 20px; */
  width: 100%;

  @media (max-width: 767px) {
    padding-left: 0;
  }

  @media (max-width: 575px) {
    padding: 0;
  }
}

.desktopInputRoot>input {
  &::placeholder {
    @media (max-width: 767px) {
      font-size: 13px;
    }

    @media (max-width: 621px) {
      font-size: 12px;
    }
  }
}

.keywordSearch {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  & .searchFields {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
    @media (max-width: 767px) {
      flex-direction: column;
    }

    & .searchBox {
      width: calc(100% / 5);
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0px 4px;

      &:first-child {
        margin-bottom: 10px;
        width: 100%;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 10px;
      }

      &>label {
        color: #ddd;
        font-weight: 500;
        width: 100%;
        padding: 0px;
        margin-bottom: 10px;
        font-size: 15px;
      }

      & .searchFld {
        position: relative;
      }

      & .locationField {
        display: flex;
        align-items: center;
        height: 100%;

        &>div {
          height: 100%;
          width: 100%;

          &>div {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border: none;

            &>svg {
              width: 14px;
              margin-top: 10px;
            }
          }

          &>input {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border: none;
            padding: 0;
            font-size: 15px;
            font-weight: 400;
          }
        }
      }


      & .field {
        padding: 0rem;
        border: none;
        height: 100%;
        cursor: text;

        &>input {
          width: 100%;
          padding: 8px 15px;
          height: 40px;
          border-radius: 4px;
          font-weight: 400;
          font-size: 15px;
          border: 0;
          background-color: #fff;

          &:focus,
          &:hover {
            border-color: #dedede;
          }
        }
      }
    }
  }

  & .button {
    display: inline-flex;
    align-items: center;
    border-radius: 0px;
    background-color: var(--matterColorLight);
    border: solid 1px var(--marketplaceColor);
    font-size: 20px;
    font-weight: var(--fontWeightLight);
    color: var(--matterColorDark);
    text-align: center;
    text-decoration: none;
    letter-spacing: normal;
    transition: all var(--transitionStyleButton);
    cursor: pointer;
    padding: 10px 40px;

    &:hover {
      background-color: transparent;
      border: solid 1px var(--matterColorLight);
      color: var(--matterColorLight);
    }
  }
}

.datesContainer {
  display: flex;
  position: relative;
  height: 100%;
  margin: 0 15px;

  @media (--viewportLarge) {
    margin: 0 5%;
  }

  &>div:first-child {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    /* border-right: 1px solid #cfcfcf; */
    @media (max-width: 575px) {
      border-right: 0;
    }
  }

  & .dates {
    width: 310px;
    position: absolute;
    top: 70px;
    left: -10px;
    background: #fff;
    z-index: 10;

    @media (max-width: 767px) {
      top: 53px;
    }

    @media (max-width: 575px) {
      left: 12px;
      top: 48px;
    }
  }

  & .datesShadow {
    box-shadow: -1px 2px 9px 1px #2b2b2b6e;
  }
}

.icon {
  width: 25px;

  @media (--viewportLarge) {
    margin-right: 16px;
    margin-left: 16px;
  }

  @media (--viewportMedium) {
    margin-right: 10px;
    margin-left: 10px;
  }

  @media (max-width: 767px) {
    width: 15px !important;
    margin-right: 8px;
    margin-left: 8px;
  }
}

.iconMap {
  width: 16px;
  margin-right: 8px;
}

.buttonMap {
  /* flex: 1 1; */
  /* display: inline-flex; */
  /* max-width: 150px; */
  /* height: 100%; */
  /* border: none; */
  border-radius: 23px;
  background-color: var(--marketplaceColor);
  font-size: 15px;
  font-weight: var(--fontWeightBold);
  line-height: 5px;
  color: var(--matterColorLight);
  text-align: center;
  text-decoration: none;
  letter-spacing: normal;
  transition: all var(--transitionStyleButton);
  cursor: pointer;
  padding: 18px 20px 20px 20px;
  margin-left: 20%;
  justify-content: center;
  align-items: center;
  margin: auto;

  &:hover {
    background-color: #7862e4;
  }

  @media (max-width: 767px) {
    line-height: unset !important;
    font-weight: 600;
  }
}

.submitButtonSearch {
  background: #0484fc !important;
  color: antiquewhite;
  height: 45px;
  text-align: center !important;
  padding: 6px;
  border-radius: 7px;
}

.mobileLocationField {
  display: flex;
  align-items: center;
  height: 100%;

  &>div {
    height: 100%;
    width: 100%;

    &>div {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: none;

      &>svg {
        width: 14px;
        margin: 10px;
      }
    }
    
    &>input {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border: none;
      padding: 9px;
      font-size: 15px;
      font-weight: 400;
    }
  }
}